import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useAppSelector, useAppDispatch } from '../store/hooks';
import { toggleOpenMenu } from '../store/menuSlice';

const ModuleWrapper = styled.div`
  position: fixed;
  width: max-content;
  cursor: pointer;
  display: none;
  top: 40px;
  left: 40px;
  width: max-content;
  z-index: 1000;
  @media (max-width: 1200px) {
    top: 30px;
    left: 30px;
    display: block;
  }
  @media (max-width: 850px) {
  }
  @media (max-width: 550px) {
    top: 38px;
    left: 16px;
  }
`;

const Text = styled.p`
  font-size: 12px;
  line-height: 14px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-family: 'Barlow';
`;

interface ComponentProps { 
  text?:string
}

const MenuToggle: React.FC<ComponentProps> = ({text}) => {
  const isMenuOpen = useAppSelector((state) => state.menu.isMenuOpen);
  const dispatch = useAppDispatch();

  return (
    <ModuleWrapper>
      <Text onClick={() => dispatch(toggleOpenMenu())}>
        <span className="blue">{text} </span>
        <br />
        menu
      </Text>
    </ModuleWrapper>
  );
};

export default MenuToggle;
