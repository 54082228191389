import React, { useState, useRef, useEffect } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Layout from '../templates/Layout';
import Logo from './Logo';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { visibleLogoDescription, setScrollPosition, setActiveSection, hiddenLogoDescription, setActiveTextBottom, setActiveTextBottomHover } from '../store/menuSlice';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import Start from '../assets/menu-start.svg';

// if (typeof window !== 'undefined') {
//   // Make scroll behavior of internal links smooth
//   // eslint-disable-next-line global-require
//   require('smooth-scroll')('a[href*="#"]');
// }

const ModuleWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 100;
  position: relative;
  backdrop-filter: blur(17px);
  @media (max-width: 1200px) {
    display: none;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: auto;
`;

const NavWrapper = styled.div`
align-items: flex-end;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: right;
`;

const SocialWrapper = styled.div`
  width: auto;
  height: auto;
  display: flex;
  margin-top: 100px;
  justify-content: space-between;
  align-items: center;
  /* grid-gap:40px;
  grid-template-columns: 1fr 1fr 1fr; */
  position: relative;
  right:0;
  margin-right:0;
  padding-left:40%;
  @media (max-width:1440px){
    padding-left:20%;
  }
`;

interface StyledLinkProps {
  name?: string,
  color?: string,
}

const StyledLink = styled(Link) <StyledLinkProps>`
  font-family: 'Staatliches';
  text-align: right;
  font-size: 35px;
  line-height: 30px;

  color: black;
  position: relative;
  width:max-content;


  @media (max-width: 1440px) {
    font-size: 25px;
    line-height: 21px;
  }
  &.active{
    ~.cross{
      opacity:1;
    }

    &::before{
      content:"${({ name }) => name}";
      position:absolute;
      width:max-content;
      height:auto;
      bottom:3px;
      color:${({ theme, color }) => color === 'blue' ? theme.colors.blue : color === 'red' ? theme.colors.red : color === 'yellow' ? theme.colors.yellow : theme.colors.purple};
      z-index: -1;
      right:0;
      left:-3px;
      
    }
  }
  &:hover{

    &::before{
      content:"${({ name }) => name}";
      position:absolute;
      width:max-content;
      height:auto;
      bottom:3px;
      color:${({ theme, color }) => color === 'blue' ? theme.colors.blue : color === 'red' ? theme.colors.red : color === 'yellow' ? theme.colors.yellow : theme.colors.purple};
      z-index: -1;
      right:0;
      left:-3px;
    }
    &::after{
      /* content:'';
      position:absolute;
      width:10 */
    }
  }
  &.start{
    &:hover{

      
      &::after{
content:'';
background-image: url(${Start});
width: 247px;
    height: 62px;
    position: absolute;
    right: -30px;
    top: -9px;
      }
    }
  }

  &.start-active{
    ~.cross{
      opacity:1;
    }
      &::after{
content:'';
background-image: url(${Start});
width: 247px;
    height: 62px;
    position: absolute;
    right: -30px;
    top: -9px;
      }
    
  }
`;

const SocialLink = styled.a`
  font-family: 'Barlow', 'Staatliches';
  font-weight: ${({ theme }) => theme.fontWeight.extraBold};
  font-size: 12px;
  line-height: 30px;
  color: black;
  position: relative;
  &.first{
    &:hover{
          &::after{
            content:'';
            position: absolute;
            width:100%;
            height:5px;
            background-color: ${({ theme }) => theme.colors.red};
            bottom:5px;
            left:0;
            right:0;
          }
        }
  }
  &.second{
    &:hover{
          &::after{
            content:'';
            position: absolute;
            width:100%;
            height:5px;
            background-color: black;
            bottom:5px;
            left:0;
            right:0;
          }
        }
  }
  &.third{
    &:hover{
          &::after{
            content:'';
            position: absolute;
            width:100%;
            height:5px;
            border:solid 1px black;
            bottom:5px;
            left:0;
            right:0;
          }
        }
  }

  span {
    font-weight: ${({ theme }) => theme.fontWeight.extraBold};
    &.number {
      position: relative;
      &.first {
        color: ${({ theme }) => theme.colors.red};
      }
      &.second {
        color: black;
      }
      &.third {
        color: transparent;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: black;
      }
    }
  }
`;

const StyledIcon = styled.img`
  width: 220px;
  height: auto;
  position: relative;
  right: -30px;
`;

const StyledX = styled(Link)`
position: absolute;
z-index:10000;
left: -35px;
bottom: 0;
top: -2px;
padding: 16px 22px 17px 9px;
opacity:0;

&.start{
  left:-110px;
}
&.cross{
  opacity:0;
}


&::after{
  content:'';
  position:absolute;
  width:16px;
  height:4px;
  background-color: ${({ theme }) => theme.colors.red};
  transform:rotate(-45deg);
}
&::before{
  content:'';
  position:absolute;
  width:16px;
  height:4px;
  background-color: ${({ theme }) => theme.colors.red};
  transform:rotate(45deg);
}
@media (max-width:1440px){
  top:-8px;
}
`


const LinkWrapper = styled.div`
position: relative;
margin-top: 20px;
  &:last-of-type {
    margin-top: 50px;
  }




`

interface ComponentProps {
  data?: any
  // handleChange:(event:React.ChangeEvent<HTMLInputElement>) => void;
}

const Menu: React.FC<ComponentProps> = ({ data }) => {
 
  const datoCmsMenuModule = data?.datoCmsMenuModule || '';
  const isLogoDescription = useAppSelector((state) => state.menu.isLogoDescription);
  const scrollPosition = useAppSelector((state) => state.menu.scrollPosition);
  const activeSection = useAppSelector((state) => state.menu.activeSection);
  const activeLanguage = useAppSelector((state) => state.menu.activeLanguage)
  const dispatch = useAppDispatch();
  
  const aboutLink = data?.datoCmsMenuModule?.aboutLink.toLowerCase() || '';
  const whyUsLink = data?.datoCmsMenuModule?.whyUsLink.toLowerCase() || '';
  const whatMakeUsStandOutLink = data?.datoCmsMenuModule?.whatMakeUsStandOutLink.toLowerCase() || '';
  const factorXLink = data?.datoCmsMenuModule?.factorXLink.toLowerCase() || '';
  const letsStartLink = data?.datoCmsMenuModule?.letsStartLink.toLowerCase() || '';

  const aboutTextBottom = data?.datoCmsAboutModule?.textBottom || ''
  const aboutTextBottomHover = data?.datoCmsAboutModule?.textBottomHover || ''

  const whyUsTextBottom = data?.datoCmsWhyUsModule?.textBottom || ''
  const whyUsTextBottomHover = data?.datoCmsWhyUsModule?.textBottomHover || ''

  const whatMakeUsStandOutTextBottom = data?.datoCmsWhatMakeUsStandOutModule?.textBottom || ''
  const whatMakeUsStandOutTextBottomHover = data?.datoCmsWhatMakeUsStandOutModule?.textBottomHover || ''

  const factorXTextBottom = data?.datoCmsFactorXModule?.textBottom || ''
  const factorXTextBottomHover = data?.datoCmsFactorXModule?.textBottomHover || ''

  useEffect(() => {


    switch (scrollPosition) {
      case 'about':
        dispatch(setActiveTextBottom(aboutTextBottom))
        dispatch(setActiveTextBottomHover(aboutTextBottomHover))
        break;
        case 'why-us':
          dispatch(setActiveTextBottom(whyUsTextBottom))
          dispatch(setActiveTextBottomHover(whyUsTextBottomHover))
        break;
        case 'what-make-us-stand-out':
          dispatch(setActiveTextBottom(whatMakeUsStandOutTextBottom))
          dispatch(setActiveTextBottomHover(whatMakeUsStandOutTextBottomHover))
          break;
          case 'factor-x':
            dispatch(setActiveTextBottom(factorXTextBottom))
            dispatch(setActiveTextBottomHover(factorXTextBottomHover))
            break;
            case 'lets-start':
              dispatch(setActiveTextBottom(''))
              dispatch(setActiveTextBottomHover(''))
              break;
              case 'home':
                dispatch(setActiveTextBottom(''))
                dispatch(setActiveTextBottomHover(''))
                break;
    
      default:
        break;
    }
  }, [activeLanguage])


  const aboutHandler = (name: string) => {
    dispatch(setScrollPosition('about'))
    dispatch(visibleLogoDescription())
    dispatch(setActiveSection(name))
    dispatch(setActiveTextBottom(aboutTextBottom))
    dispatch(setActiveTextBottomHover(aboutTextBottomHover))
  }

  const whyUsHandler = (name: string) => {
    dispatch(setScrollPosition('why-us'))
    dispatch(visibleLogoDescription())
    dispatch(setActiveSection(name))
    dispatch(setActiveTextBottom(whyUsTextBottom))
    dispatch(setActiveTextBottomHover(whyUsTextBottomHover))

  }

  const whatMakeUsStandOutHandler = (name: string) => {
    dispatch(setScrollPosition('what-make-us-stand-out'))
    dispatch(visibleLogoDescription())
    dispatch(setActiveSection(name))
    dispatch(setActiveTextBottom(whatMakeUsStandOutTextBottom))
    dispatch(setActiveTextBottomHover(whatMakeUsStandOutTextBottomHover))
  }

  const factorXHandler = (name: string) => {
    dispatch(setScrollPosition('factor-x'))
    dispatch(visibleLogoDescription())
    dispatch(setActiveSection(name))
    dispatch(setActiveTextBottom(factorXTextBottom))
    dispatch(setActiveTextBottomHover(factorXTextBottomHover))
  }

  const letsStartHandler = (name: string) => {

    dispatch(setScrollPosition('lets-start'))
    dispatch(visibleLogoDescription())
    dispatch(setActiveSection(name))
    dispatch(setActiveTextBottom(''))
    dispatch(setActiveTextBottomHover(''))
  }

  const xHandler = (name: string) => {
    setTimeout(function () {
      dispatch(setScrollPosition('home'))
      dispatch(hiddenLogoDescription())
      dispatch(setActiveSection(name))
      dispatch(setActiveTextBottom(''))
      dispatch(setActiveTextBottomHover(''))
    }, 10);
  }


  return (
    <ModuleWrapper>
      <ContentWrapper>
        <NavWrapper>


          <LinkWrapper>
            <StyledLink name={aboutLink} color={'blue'} className={scrollPosition === 'about' ? 'active' : ''} onClick={() => aboutHandler(aboutLink)} to="#about">
              {aboutLink}
            </StyledLink>
            <StyledX className="cross" to="#home" onClick={() => xHandler('home')} />
          </LinkWrapper>

          <LinkWrapper>
            <StyledLink name={whyUsLink} color={'yellow'} className={scrollPosition === 'why-us' ? 'active' : ''} onClick={() => whyUsHandler(whyUsLink)} to="#why-us">
              {whyUsLink}

            </StyledLink>
            <StyledX className="cross" to="#home" onClick={() => xHandler('home')} />
          </LinkWrapper>

          <LinkWrapper>
            <StyledLink name={whatMakeUsStandOutLink} color={'red'} className={scrollPosition === 'what-make-us-stand-out' ? 'active' : ''} onClick={() => whatMakeUsStandOutHandler(whatMakeUsStandOutLink)} to="#what-make-us-stand-out">
              {whatMakeUsStandOutLink}

            </StyledLink>
            <StyledX className="cross" to="#home" onClick={() => xHandler('home')} />
          </LinkWrapper>

          <LinkWrapper>
            <StyledLink name={factorXLink} color={'purple'} className={scrollPosition === 'factor-x' ? 'active' : ''} onClick={() => factorXHandler(factorXLink)} to="#factor-x">
              {factorXLink}

            </StyledLink>
            <StyledX className="cross" to="#home" onClick={() => xHandler('home')} />
          </LinkWrapper>

          <LinkWrapper>
            <StyledLink name={letsStartLink} className={`${scrollPosition === "lets-start" ? 'start-active' : ''} start`} onClick={() => letsStartHandler(letsStartLink)} to="#lets-start">
              {letsStartLink}
            </StyledLink>
            <StyledX to="#home" className="start cross" onClick={() => xHandler('home')} />
          </LinkWrapper>


        </NavWrapper>
        <SocialWrapper>
          <SocialLink className="first" target="_blank" href={datoCmsMenuModule?.linkedinUrl}>
            <span className="number first">01 </span>linkedin
          </SocialLink>
          <SocialLink className="second" target="_blank" href={datoCmsMenuModule?.facebookUrl}>
            <span className="number second">02 </span>facebook
          </SocialLink>
          <SocialLink className="third" target="_blank" href={datoCmsMenuModule?.instagramUrl}>
            <span className="number third">03 </span>instagram
          </SocialLink>
        </SocialWrapper>
      </ContentWrapper>
    </ModuleWrapper>
  );
};

export default Menu;
