import React, { useState, useRef } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Layout from '../templates/Layout';
import Seo from '../components/seo';
import Logo from './Logo';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { setActiveTextBottom, setActiveTextBottomHover, toggleOpenMenu,setScrollPosition } from '../store/menuSlice';
import Start from '../assets/menu-start.svg';

const ModuleWrapper = styled.div`
  padding: 10% 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 900;
  position: fixed;
  backdrop-filter: blur(50px);
  background-color: rgba(255, 255, 255, 0.7);
  transform: translateY(-1500px);
  transition: all 0.4s;
  opacity: 0;
  width: 100vw;
  height: 100%;
  min-height: -webkit-fill-available;


  &.visible {
    transform: translateY(0px);
    opacity: 1;
  }
  &.hidden {
    transform: translateY(-1500px);
    opacity: 0;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: auto;
`;

const NavWrapper = styled.div`
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: right;
`;

const SocialWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const StyledLink = styled(Link)`
  font-family: 'Staatliches';
  text-align: right;
  font-size: 35px;
  line-height: 30px;
  margin-top: 25px;
  color: black;
  &:last-of-type {
    margin-top: 50px;
  }
  @media (max-width: 550px) {
    font-size: 25px;
    line-height: 21px;
  }
  &.start{
    &:hover{

      
      &::after{
content:'';
background-image: url(${Start});
width: 247px;
    height: 62px;
    position: absolute;
    right: -30px;
    top: -15px;
      }
    }
  }
`;

const SocialLink = styled.a`
  font-family: 'Barlow', 'Staatliches';
  font-size: 15px;
  line-height: 38px;
  color: black;
  padding: 0 30px;
  span {
    font-weight: ${({ theme }) => theme.fontWeight.extraBold};
    &.number {
      &.first {
        color: ${({ theme }) => theme.colors.red};
      }
      &.second {
        color: black;
      }
      &.third {
        color: transparent;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: black;
      }
    }
  }
  @media (max-width: 550px) {
    font-size: 11px;
    line-height: 23px;
    padding: 0 16px;
  }
`;

interface ComponentProps {
  data?: any
}

const MenuMobile: React.FC<ComponentProps> = ({ data }) => {
  const isMenuOpen = useAppSelector((state) => state.menu.isMenuOpen);
  const dispatch = useAppDispatch();
  const datoCmsMenuModule = data?.datoCmsMenuModule || '';

  const aboutLink = data?.datoCmsMenuModule?.aboutLink.toLowerCase() || '';
  const whyUsLink = data?.datoCmsMenuModule?.whyUsLink.toLowerCase() || '';
  const whatMakeUsStandOutLink = data?.datoCmsMenuModule?.whatMakeUsStandOutLink.toLowerCase() || '';
  const factorXLink = data?.datoCmsMenuModule?.factorXLink.toLowerCase() || '';
  const letsStartLink = data?.datoCmsMenuModule?.letsStartLink.toLowerCase() || '';

  const aboutTextBottom = data?.datoCmsAboutModule?.textBottom || ''
  const aboutTextBottomHover = data?.datoCmsAboutModule?.textBottomHover || ''

  const whyUsTextBottom = data?.datoCmsWhyUsModule?.textBottom || ''
  const whyUsTextBottomHover = data?.datoCmsWhyUsModule?.textBottomHover || ''

  const whatMakeUsStandOutTextBottom = data?.datoCmsWhatMakeUsStandOutModule?.textBottom || ''
  const whatMakeUsStandOutTextBottomHover = data?.datoCmsWhatMakeUsStandOutModule?.textBottomHover || ''

  const factorXTextBottom = data?.datoCmsFactorXModule?.textBottom || ''
  const factorXTextBottomHover = data?.datoCmsFactorXModule?.textBottomHover || ''



  const aboutHandler = (name: string) => {
    dispatch(setScrollPosition('about'))
    // dispatch(visibleLogoDescription())
    // dispatch(setActiveSection(name))
    dispatch(setActiveTextBottom(aboutTextBottom))
    dispatch(setActiveTextBottomHover(aboutTextBottomHover))
    dispatch(toggleOpenMenu())
  }

  const whyUsHandler = (name: string) => {
    dispatch(setScrollPosition('why-us'))
    // dispatch(visibleLogoDescription())
    // dispatch(setActiveSection(name))
    dispatch(setActiveTextBottom(whyUsTextBottom))
    dispatch(setActiveTextBottomHover(whyUsTextBottomHover))
    dispatch(toggleOpenMenu())

  }

  const whatMakeUsStandOutHandler = (name: string) => {
    dispatch(setScrollPosition('what-make-us-stand-out'))
    // dispatch(visibleLogoDescription())
    // dispatch(setActiveSection(name))
    dispatch(setActiveTextBottom(whatMakeUsStandOutTextBottom))
    dispatch(setActiveTextBottomHover(whatMakeUsStandOutTextBottomHover))
    dispatch(toggleOpenMenu())
  }

  const factorXHandler = (name: string) => {
    dispatch(setScrollPosition('factor-x'))
    // dispatch(visibleLogoDescription())
    // dispatch(setActiveSection(name))
    dispatch(setActiveTextBottom(factorXTextBottom))
    dispatch(setActiveTextBottomHover(factorXTextBottomHover))
    dispatch(toggleOpenMenu())
  }

  const letsStartHandler = (name: string) => {

    dispatch(setScrollPosition('lets-start'))
    // dispatch(visibleLogoDescription())
    // dispatch(setActiveSection(name))
    dispatch(setActiveTextBottom(''))
    dispatch(setActiveTextBottomHover(''))
    dispatch(toggleOpenMenu())
  }
  return (
    <ModuleWrapper className={isMenuOpen ? 'visible' : 'hidden'}>
      <div></div>

      <NavWrapper>
        <StyledLink onClick={() => aboutHandler(aboutLink)} to="#about">
          {aboutLink}
        </StyledLink>
        <StyledLink onClick={() => whyUsHandler(whyUsLink)} to="#why-us">
          {whyUsLink}
        </StyledLink>
        <StyledLink onClick={() => whatMakeUsStandOutHandler(whatMakeUsStandOutLink)} to="#what-make-us-stand-out">
          {whatMakeUsStandOutLink}
        </StyledLink>
        <StyledLink onClick={() => factorXHandler(factorXLink)} to="#factor-x">
          {factorXLink}
        </StyledLink>
        <StyledLink onClick={() => letsStartHandler(letsStartLink)} className="start" to="#lets-start">
          {/* <StyledIcon src={Start} /> */}
          {letsStartLink}
        </StyledLink>
      </NavWrapper>
      <SocialWrapper>
        <SocialLink target="_blank" href={datoCmsMenuModule?.linkedinUrl}>
          <span className="number first">01 </span>linkedin
        </SocialLink>
        <SocialLink target="_blank" href={datoCmsMenuModule?.facebookUrl}>
          <span className="number second">02 </span>facebook
        </SocialLink>
        <SocialLink target="_blank" href={datoCmsMenuModule?.instagramUrl}>
          <span className="number third">03 </span>instagram
        </SocialLink>
      </SocialWrapper>
    </ModuleWrapper>
  );
};

export default MenuMobile;
