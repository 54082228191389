import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'gatsby';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { setScrollPosition, hiddenLogoDescription, selectIsLogoDescription, setActiveSection, setActiveTextBottom } from '../store/menuSlice';

const LogoWrapper = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 1000;
  top: 40px;
  right: 40px;
  width: max-content;
  @media (max-width: 1200px) {
    top: 30px;
    right: 30px;
  }
  @media (max-width: 550px) {
    top: 30px;
    right: 16px;
  }
`;

const rotate = keyframes`
  from {
    /* transform: matrix(1, 0, 0, 1, 0, 0); */
    transform:rotate(0deg);
    bottom: 0px;
  }

  to {
    /* transform: matrix(-1, 0, 0, 1, 0, 0); */
    transform:rotate(180deg);
    bottom: -5px;
  }
`;

const unrotate = keyframes`
  from {
    /* transform: matrix(1, 0, 0, 1, 0, 0); */
    transform:rotate(180deg);
    bottom: -5px;

  }

  to {
    /* transform: matrix(-1, 0, 0, 1, 0, 0); */
    transform:rotate(0deg);
    bottom: 0px;
  }
`;

interface MainTextProps {
  text: string;
}

const LogoMain = styled.div<MainTextProps>`
  font-family: 'Oswald';
  text-align: right;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 55px;
  line-height: 55px;
  letter-spacing: -4px;
  color: black;
  text-transform: uppercase;
  transition: all 0.3s;
  &:hover {
    .rotate {
      animation-name: ${rotate};
      animation-timing-function: ease-in-out;
      animation-duration: 0.2s;
      animation-fill-mode: both;
      animation-direction: normal;
    }
  }

  .rotate {
    position: relative;
    display: inline-block;
    animation-name: ${unrotate};
      animation-timing-function: ease-in-out;
      animation-duration: 0.2s;
      animation-fill-mode: both;
      animation-direction: normal;
  }

  .fancy-text {
    position: relative;
    z-index: 101;
    &::before {
      content: '${({ text }) => text}';
      font-family: 'Oswald';
      font-size: inherit;
      line-height: inherit;
      letter-spacing: inherit;
      position: absolute;
      z-index: -2;
      color: ${({ theme }) => theme.colors.blue};
      bottom: 10%;
      right: 3%;
    }
    &::after {
      content: '${({ text }) => text}';
      font-family: 'Oswald';
      font-size: inherit;
      line-height: inherit;
      letter-spacing: inherit;
      position: absolute;
      z-index: -1;
      color: ${({ theme }) => theme.colors.red};
      top: 10%;
      left: 5%;
    }
  }
  @media (max-width: 1440px) {
    font-size: 33px;
    line-height: 45px;
    letter-spacing: -2px;

    .fancy-text {
      &::before {
        bottom: 10%;
        right: 3%;
      }
      &::after {
        top: 10%;
        left: 5%;
      }
    }
  }
`;

const LogoDescription = styled.h2`
  font-family: 'Oswald';
  text-align: right;
  font-size: 12px;
  line-height: 30px;
  color: black;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  text-transform: uppercase;
  position: relative;
  &.visible{
    &::after{
      transition:all 0.3s;
      content:'';
      width:100%;
      height: inherit;
      position:absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;
      background-color: white;
      @media (max-width: 1200px) {
    opacity:0;
  }
    }
  }
  &.hidden{
    &::after{
      transition:all 0.3s;
      content:'';
      width:100%;
      height: inherit;
      position:absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;
      transform: translateX(101%);
      background-color: white;
      @media (max-width: 1200px) {
    opacity:0;
  }
    }
  }
  @media (max-width: 1200px) {
    display: none;
    opacity:0;
  }
  @media (max-width: 550px) {
    /* display: inline; */
    font-size: 8px;
    line-height: 8px;
  }
`;

interface ComponentProps {
  fromMenu?: boolean;
  data:any
}

const Logo: React.FC<ComponentProps> = ({ fromMenu,data }) => {
  const isLogoDescription = useAppSelector((state) => state.menu.isLogoDescription);
  const dispatch = useAppDispatch();
  const logoHandler = (name: string) => {
    dispatch(hiddenLogoDescription())
    dispatch(setScrollPosition('home'))
    dispatch(setActiveSection(name))
    dispatch(setActiveTextBottom(''))
  }

  const description = data?.datoCmsMenuModule?.logoDescription || ''

  return (
    <LogoWrapper className={fromMenu ? 'menu' : 'layout'}>
      <LogoMain onClick={() => logoHandler('home')} text="d" as="a" href="#home">
        Vivi<span className="fancy-text">d</span> <span className="rotate">D</span>esign <span className="thin">Art</span>
      </LogoMain>

        <LogoDescription className={isLogoDescription ? 'hidden' : 'visible'}>{description}</LogoDescription>

    </LogoWrapper>
  );
};

export default Logo;
