import { createGlobalStyle } from 'styled-components';

import OswaldBold from '../assets/fonts/Oswald-Bold.woff2';
import OswaldExtraLight from '../assets/fonts/Oswald-ExtraLight.woff2';
import OswaldLight from '../assets/fonts/Oswald-Light.woff2';
import OswaldMedium from '../assets/fonts/Oswald-Medium.woff2';
import OswaldRegular from '../assets/fonts/Oswald-Regular.woff2';
import OswaldSemiBold from '../assets/fonts/Oswald-SemiBold.woff2';

import BarlowBold from '../assets/fonts/Barlow-Bold.woff2';
import BarlowExtraLight from '../assets/fonts/Barlow-ExtraLight.woff2';
import BarlowLight from '../assets/fonts/Barlow-Light.woff2';
import BarlowMedium from '../assets/fonts/Barlow-Medium.woff2';
import BarlowRegular from '../assets/fonts/Barlow-Regular.woff2';
import BarlowSemiBold from '../assets/fonts/Barlow-SemiBold.woff2';
import BarlowThin from '../assets/fonts/Barlow-Thin.woff2';
import BarlowExtraBold from '../assets/fonts/Barlow-ExtraBold.woff2';
import BarlowBlack from '../assets/fonts/Barlow-Black.woff2';

import StaatlichesRegular from '../assets/fonts/Staatliches-Regular.woff2';

const GlobalStyle = createGlobalStyle`
  /* @import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900');
  @import url('https://fonts.googleapis.com/css2?family=Yrsa:wght@300;400;500;600;700'); */

  
  @font-face {
      font-family: 'Oswald';
      font-display: block;
      font-style: normal;
      font-weight: 200;
      src: url(${OswaldExtraLight}) format('opentype');
  }
  @font-face {
      font-family: 'Oswald';
      font-display: block;
      font-style: normal;
      font-weight: 300;
      src: url(${OswaldLight}) format('opentype');
  }
  @font-face {
      font-family: 'Oswald';
      font-display: block;
      font-style: normal;
      font-weight: 400;
      src: url(${OswaldRegular}) format('opentype');
  }
  @font-face {
      font-family: 'Oswald';
      font-display: block;
      font-style: normal;
      font-weight: 500;
      src: url(${OswaldMedium}) format('opentype');
  }
  @font-face {
      font-family: 'Oswald';
      font-display: block;
      font-style: normal;
      font-weight: 600;
      src: url(${OswaldSemiBold}) format('opentype');
  }
  @font-face {
      font-family: 'Oswald';
      font-display: block;
      font-style: normal;
      font-weight: 700;
      src: url(${OswaldBold}) format('opentype');
  }
  @font-face {
      font-family: 'Barlow';
      font-display: block;
      font-style: normal;
      font-weight: 100;
      src: url(${BarlowThin}) format('opentype');
  }
  @font-face {
      font-family: 'Barlow';
      font-display: block;
      font-style: normal;
      font-weight: 200;
      src: url(${BarlowExtraLight}) format('opentype');
  }
  @font-face {
      font-family: 'Barlow';
      font-display: block;
      font-style: normal;
      font-weight: 300;
      src: url(${BarlowLight}) format('opentype');
  }
  @font-face {
      font-family: 'Barlow';
      font-display: block;
      font-style: normal;
      font-weight: 400;
      src: url(${BarlowRegular}) format('opentype');
  }
  @font-face {
      font-family: 'Barlow';
      font-display: block;
      font-style: normal;
      font-weight: 500;
      src: url(${BarlowMedium}) format('opentype');
  }
  @font-face {
      font-family: 'Barlow';
      font-display: block;
      font-style: normal;
      font-weight: 600;
      src: url(${BarlowSemiBold}) format('opentype');
  }
  @font-face {
      font-family: 'Barlow';
      font-display: block;
      font-style: normal;
      font-weight: 700;
      src: url(${BarlowBold}) format('opentype');
  }

  @font-face {
      font-family: 'Barlow';
      font-display: block;
      font-style: normal;
      font-weight: 800;
      src: url(${BarlowExtraBold}) format('opentype');
  }

  @font-face {
      font-family: 'Barlow';
      font-display: block;
      font-style: normal;
      font-weight: 900;
      src: url(${BarlowBlack}) format('opentype');
  }




  @font-face {
      font-family: 'Staatliches';
      font-display: block;
      font-style: normal;
      font-weight: 400;
      src: url(${StaatlichesRegular}) format('opentype');
  }


  *, *::before, *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    scroll-behavior:smooth;
    overflow-x:hidden;
    overflow-y:auto;
    font-family:'Staatliches','Oswald',sans-serif;
    height: -webkit-fill-available;
    
  }

  body {
    font-family:'Staatliches','Oswald',sans-serif;
    margin: 0;
    padding: 0;
    scroll-behavior:smooth;
    height: -webkit-fill-available;
  }

  ::-webkit-scrollbar {
  	width: 0px;
    border-radius: 100px;
	  background-color: #F5F5F5;
  }

  ::-webkit-scrollbar-track {
    border-radius: 100px;
    background-color: #F5F5F5;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: #ddd;
  }

h1, h2, h3, h4, h5, h6, p{
  margin:0
}

a{
  text-decoration:none;
  color:unset;
  position: relative;
}

.black{
  color:black;
}

.white{
  color:white;
}

.blue{
  color: ${({ theme }) => theme.colors.blue};
}

.fancy-text{
 
}

.thin{
  font-weight: ${({ theme }) => theme.fontWeight.thin};
}

.carousel.carousel-slider .control-arrow{
  opacity:1;
  background-color:transparent;
  width:80px;
  height:80px;
  border-radius:100px;
  border:2px solid white;
  margin:auto 40px;
  position:absolute;
  &::after{
    content:'';
    width: 12px;
    height: 5px;
    transform:rotate(-45deg);
    background-color: white;
    display: inline-block;
    position: relative;
    left: -12px;
    top: -8px;
  }
  &::before{
    content:'';
    width: 12px;
    height: 5px;
    transform:rotate(45deg);
    background-color: white;
    display: inline-block;
    border:0;
    position:relative;
    left:5px;
    top:-2px;

  }
  &:hover{
    background-color: transparent;
  }
}

.carousel.carousel-slider .control-arrow:hover{
  opacity:1;
  @media (max-width:550px){
    opacity:0;
  }
}


.carousel .control-next.control-arrow{
  
  &::after{
    content:'';
    width: 12px;
    height: 5px;
    transform:rotate(45deg);
    background-color: white;
    display: inline-block;
    position: relative;
    left: -10px;
    top: -8px;
  }
  &::before{
    content:'';
    width: 12px;
    height: 5px;
    transform:rotate(-45deg);
    background-color: white;
    display: inline-block;
    border:0;
    position:relative;
    left:7px;
    top:-2px;

  }
}

`;

export default GlobalStyle;
