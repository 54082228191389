import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Paragraph from '../typography/Paragraph'
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setActiveLanguage, closeMenu } from '../../store/menuSlice';
import {Link} from 'gatsby'

const ModuleWrapper = styled.div`
  position: fixed;
  top:0;
  left:0;
  height: 110px;
  width:100px;
  cursor:pointer;
  z-index: 99999999;
  &.desktop{
    display:block;
    @media (max-width: 1024px) {
      display:none;
    }
  }
  @media (max-width: 1024px) {
    height: 0px;
  width:0px;
    }
  &{
      a.rest:nth-of-type(1){
        top:80px;
        left:90px;
        position: fixed;
        @media (max-width: 1024px) {
          top:70px;
    }
      }

      a.rest:nth-of-type(2){
        top:120px;
        left:130px;
        position: fixed;
        @media (max-width: 1024px) {
          top:70px;
          left:150px;
    }
      }
  }
  /* &:hover{
      div:first-of-type{
        transform: translate(50px,50px) rotate(45deg);
      }
  } */
`;

const StyledText = styled.p`
    font-size: 16px;
    line-height: 40px;
    font-weight: 700;
    font-family: 'Barlow';
    transition:all 0.3s;
    display: inline;
    text-transform: uppercase;

   
    &.visible{
        display: inline;
    }
    &.hidden{
        display: none;
    }
    &.rest{

    }
    &.lang{
        margin-left:4px;
        font-weight: ${({theme}) => theme.fontWeight.regular};
        text-transform: lowercase;
    }
    
`

const TextWrapper = styled.div`
    position: relative;
    top:40px;
    left:50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: 1024px) {
      top:70px;
    left:32px;
    }
    @media (max-width: 550px) {
          left:16px;
    }
`
const Line = styled.div`
width:300px;
height: 5px;
background-color:${({theme}) => theme.colors.blue};
position: fixed;
left:-150px;
transition: all 0.3s;
transform: translate(-50px,-50px) rotate(45deg);
@media (max-width: 1024px) {
display:none
    }

&.long{
    transform: translate(50px,50px) rotate(45deg);
}

&.short{
    transform: translate(-50px,-50px) rotate(45deg);
}
` 

interface ComponentProps {
language?:string
}

const ChangeLanguage: React.FC<ComponentProps> = ({language}) => {
    const [isLanguageOpen,setIsLanguageOpen]= useState<boolean>(false)
    const dispatch = useAppDispatch();
    const activeLanguage = useAppSelector((state) => state.menu.activeLanguage)
    const scrollPosition = useAppSelector((state) => state.menu.scrollPosition)
    const isMenuOpen = useAppSelector((state) => state.menu.isMenuOpen)

    const enHandler = () =>{
      dispatch(setActiveLanguage('en'))
      dispatch(closeMenu())
    }

    const plHandler = () =>{
      dispatch(setActiveLanguage('pl'))
      dispatch(closeMenu())
    }

    const esHandler = () =>{
      dispatch(setActiveLanguage('es'))
      dispatch(closeMenu())
    }

  return (
    <>
    {isMenuOpen && (    <ModuleWrapper onClick={()=> setIsLanguageOpen(!isLanguageOpen)}>
    <Line className={isLanguageOpen ? 'long' : 'short'} />
    <TextWrapper>
    <StyledText>{activeLanguage}</StyledText>
    <StyledText className={` lang ${isLanguageOpen ? 'hidden' : 'visible'}`} >{language}</StyledText>

    </TextWrapper>
   {activeLanguage !== 'en' && (
         <StyledText as={Link} to={'/#' + scrollPosition} onClick={() =>enHandler() } className={` rest ${isLanguageOpen ? 'visible' : 'hidden'}`}>EN</StyledText>
   )}

    {activeLanguage !== 'es' && (
            <StyledText as={Link} to={'/es#' + scrollPosition} onClick={() =>esHandler() }  className={` rest ${isLanguageOpen ? 'visible' : 'hidden'}`}>ES</StyledText>
            )}

    {activeLanguage !== 'pl' && (
                <StyledText as={Link} to={'/pl#' + scrollPosition} onClick={() =>plHandler() } className={` rest ${isLanguageOpen ? 'visible' : 'hidden'}`}>PL</StyledText>
            )}
</ModuleWrapper>)}

<ModuleWrapper className="desktop" onClick={()=> setIsLanguageOpen(!isLanguageOpen)}>
    <Line className={isLanguageOpen ? 'long' : 'short'} />
    <TextWrapper>
    <StyledText>{activeLanguage}</StyledText>
    <StyledText className={` lang ${isLanguageOpen ? 'hidden' : 'visible'}`} >{language}</StyledText>

    </TextWrapper>
   {activeLanguage !== 'en' && (
         <StyledText as={Link} to={'/#' + scrollPosition} onClick={() =>enHandler() } className={` rest ${isLanguageOpen ? 'visible' : 'hidden'}`}>EN</StyledText>
   )}

    {activeLanguage !== 'es' && (
            <StyledText as={Link} to={'/es#' + scrollPosition} onClick={() =>esHandler() }  className={` rest ${isLanguageOpen ? 'visible' : 'hidden'}`}>ES</StyledText>
            )}

    {activeLanguage !== 'pl' && (
                <StyledText as={Link} to={'/pl#' + scrollPosition} onClick={() =>plHandler() } className={` rest ${isLanguageOpen ? 'visible' : 'hidden'}`}>PL</StyledText>
            )}
</ModuleWrapper>
</>

  );
};

export default ChangeLanguage;


